import { GetServerSideProps } from 'next'
import getConfig from 'next/config'
import Layout, { VerticalAlign } from '../components/layouts/Layout'
import { useAppContext } from '../components/context/AppContext'
import Brand from '../components/brand/Brand'
import LoginForm, { LoginData } from '../components/auth/LoginForm'
import { useState } from 'react'
import withoutAuth from '../lib/withoutAuth'
import FooterLinks from '../components/footer/FooterLinks'
import indexStyles from '../styles/modules/Index.module.scss'
import SmartFormTrigger from '../components/smartform/SmartFormTrigger'

const signin = async (email, password): Promise<any> => {
  const {
    publicRuntimeConfig: { serverName },
  } = getConfig()

  const response = await fetch(`https://${serverName}/v2/api/sessions`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      session: {
        email,
        password,
      },
    }),
  })

  const data = await response.json()

  await fetch(`/api/v1/auth/session`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      token: data.token,
      origin: data.origin,
      user_id: data.user_id,
    }),
  })

  return data
}

interface LoginProps {
  errorMessage?: string
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  return withoutAuth(context)(async () => {
    const error = context.query?.error
    let errorMessage = ''
    if (error === 'token_expired') {
      errorMessage =
        'The link used to reset your password is invalid or has expired. Please request a new one.'
    }

    return {
      props: {
        errorMessage,
      },
    }
  })
}

export default function Login(props: LoginProps): JSX.Element {
  const { variableState } = useAppContext()
  const [loginError, setLoginError] = useState(props.errorMessage)

  const requestLogin = async (data: LoginData) => {
    try {
      const result = await signin(data.email, data.password)
      if (!result.error) {
        window.location.href = '/'
      } else {
        setLoginError(result.error)
      }
    } catch (error) {
      setLoginError('Login failed.')
    }
  }

  return (
    <Layout useBrandBackground={true} verticalAlign={VerticalAlign.MIDDLE}>
      <Brand clientName={variableState.clientName} />
      <div className={`${indexStyles.container} ${indexStyles.getSupport}`}>
        <div className={`${indexStyles.content}`}>
          <h1 id="getsupport">Get Support</h1>
          <desc className={`${indexStyles.description} mb-3`}>
            What do you need help with?
          </desc>
          <SmartFormTrigger />
        </div>
        <div className={`${indexStyles.content} track-request`}>
          <h1 id="trackrequests">Track Requests</h1>
          <desc className={`${indexStyles.description} mb-3`}>
            Please log in to track your IT support requests.
          </desc>
          <LoginForm loginError={loginError} loginRequest={requestLogin} />
        </div>
      </div>
      <FooterLinks />
    </Layout>
  )
}
