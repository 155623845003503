import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Routes } from '../../lib/routes'
import formStyles from '../../styles/modules/Form.module.scss'

export interface SFTriggerData {
  subject: string
}

const schema = yup.object().shape({
  subject: yup
    .string()
    .required()
    .max(280, 'Subject can only be up to 280 characters.'),
})

const maxCharLimit = 60

const SmartFormTrigger: React.FC<Record<string, any>> = () => {
  const [subjectCount, setSubjectCount] = useState<number>(maxCharLimit)
  const { register, handleSubmit, formState, clearErrors, setFocus } =
    useForm<SFTriggerData>({
      resolver: yupResolver(schema),
      defaultValues: {
        subject: '',
      },
      mode: 'onChange',
      shouldFocusError: false,
    })

  const errors = formState.errors

  const onSubmit = async (data: SFTriggerData) => {
    if (data.subject) {
      window.location.href = `${Routes.SMARTFORM}?topic=${data.subject}`
    }
  }

  const handleChange = (event) => {
    setSubjectCount(maxCharLimit - event.target.value.length)
  }

  const onChange = () => {
    if (errors.subject) {
      clearErrors('subject')
    }
  }

  // Set focus when mounting the component.
  useEffect(
    () => {
      setFocus('subject')
    },
    // eslint-disable-next-line
    []
  )

  return (
    <section className={`${formStyles.login}`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleChange}
        className="auth-container"
        autoComplete="off"
      >
        <div className="form-group mb-0">
          <label htmlFor="email" className="form-label">
            Subject
          </label>
          <div className="form-group__fields">
            <textarea
              className={`auth-text-input auth-text-area ${
                errors.subject ? 'is-invalid' : ''
              }`}
              {...register('subject', { maxLength: 280 })}
              defaultValue=""
              autoComplete="off"
              placeholder={`Please describe your question or issue in one sentence. \n\nYou can provide more details in the next step.`}
              onBlur={onChange}
              maxLength={maxCharLimit}
            />
          </div>
          {!errors.subject && (
            <div
              className={`${formStyles['character-counter']} ${
                subjectCount === 0 ? formStyles['red'] : ''
              }`}
            >
              {subjectCount < maxCharLimit && (
                <>
                  Remaining Characters:{' '}
                  <span className={`${formStyles.count}`}>{subjectCount}</span>
                </>
              )}
            </div>
          )}
          {errors.subject && (
            <span className={`form-error ${formStyles['subject-required']}`}>
              {errors.subject.message}
            </span>
          )}
        </div>
        <div className={`${formStyles['form-actions']}`}>
          <button
            type="submit"
            className="btn btn-purple auth-button mb-3 mt-1"
            disabled={!formState.isValid}
            data-testid="btn-request-support"
          >
            Request for IT Service
          </button>
        </div>
      </form>
    </section>
  )
}

export default SmartFormTrigger
