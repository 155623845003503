export const Routes = {
  LOGIN: '/login',
  FORGOTPWD: '/forgot-password',
  REQUESTACCOUNT: '/request-account',
  RESETPWD: '/resetPassword',
  AUTHLANDING: '/service-desk',
  GUESTLANDING: '/get-support',
  SMARTFORM: '/it-request-form',
  PRIVACY: '/privacy',
}
