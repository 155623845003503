
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/get-support",
      function () {
        return require("private-next-pages/get-support.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/get-support"])
      });
    }
  